<!-- @format -->

<template>
  <div class="home">
    <Rankings />
  </div>
</template>

<script>
// @ is an alias to /src
import Rankings from "@/components/Rankings.vue";

export default {
  name: "Home",
  components: {
    Rankings
  },
  data() {
    return {};
  },
  methods: {}
};
</script>
