<!-- @format -->

<template>
  <v-container>
    <v-app-bar
      centered
      absolute
      color="#f7ef8a"
      dark
      :src="challenge.backgroundImage"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.5), rgba(25,32,72,.5)"
        ></v-img>
      </template>
      <v-app-bar-nav-icon
        v-if="false"
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ challenge.name }} </v-toolbar-title>

      <v-spacer></v-spacer>
      <div v-if="false">
        <v-btn light v-if="!isAuthenticated" @click.prevent="submitLogin">
          Sign In
        </v-btn>
        <v-menu offset-y v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fab icon v-bind="attrs" v-on="on">
              <v-avatar>
                <img :src="user.profileUrl" :alt="user.displayName"/></v-avatar
            ></v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  style="cursor: pointer;"
                  @click.prevent="submitLogout"
                  >Sign out</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <template v-slot:extension>
        <v-tabs centered>
          <v-tab @click.prevent="changeTab('overall')">Leaderboard</v-tab>
          <v-tab @click.prevent="changeTab('holdings')">Holdings</v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab
          ><v-tab disabled @click.prevent="changeTab('week')"></v-tab
          ><v-tab disabled @click.prevent="changeTab('week')"></v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-if="false"
      absolute
      v-model="drawer"
      bottom
      temporary
    >
      <v-list nav>
        <v-list-item-group>
          <v-list-item
            v-for="c in challenges"
            @click.prevent="changeChallenge(c.id)"
            :key="c.id"
          >
            <v-list-item-icon>
              <v-icon>mdi-trophy</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ c.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <br /><br />
    <br /><br />
    <br />
    <v-row no-gutters align="left" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-card v-if="false" class="mb-2" dark>
          <v-card-text style="align: left; text-align: left; color: white">
            <strong>
              Contestants are earning 0% in interest on any dividends received.
              Boring 😒!</strong
            >
            <br />
            Listen to David talk about how the JSE worked in the "good old days"
            on Shapiro World. Fascinating.
          </v-card-text>
        </v-card>
        <v-card :loading="loading">
          <v-card-title style="padding: 5px 5px 0 18px" class="grey lighten-3">
            <small>
              <v-select
                dense
                style="font-size: 0.7em"
                @change="getResults"
                return-object
                :items="timePeriods"
                item-text="name"
                item-value="id"
                :single-line="true"
                label="Select"
                v-model="selectedTimePeriod"
              ></v-select
            ></small>
            <v-spacer></v-spacer>
            <v-btn-toggle tile group v-model="timePeriod">
              <v-btn
                @click.prevent="changeDateType('Overall')"
                small
                value="Overall"
              >
                Overall
              </v-btn>

              <v-btn small @click.prevent="changeDateType('Day')" value="Day">
                Day
              </v-btn>

              <v-btn small @click.prevent="changeDateType('Week')" value="Week">
                Week
              </v-btn>

              <v-btn
                small
                @click.prevent="changeDateType('Month')"
                value="Month"
              >
                Month
              </v-btn>
            </v-btn-toggle>
          </v-card-title>

          <v-divider class="mx-4"></v-divider>

          <v-simple-table
            v-if="tab == 'overall'"
            style="width: 100%"
            single-select="true"
          >
            <template v-slot:default>
              <thead>
                <tr v-if="false">
                  <td colspan="4">
                    <v-card
                      dark
                      class="mb-2 purple darken-2"
                      style="margin: 15px 5px 5px 5px;"
                    >
                      <v-card-text>
                        <div>Word of the Day</div>
                        <p class="display-1">
                          z·eee·rr·o·oo·o
                        </p>

                        <div class="text--white">
                          the amount of interest our contestants are earning on
                          cash dividends<br />
                          They really should do better
                        </div>
                      </v-card-text>
                      <v-card-actions style="text-align: center">
                        <v-row align="center" justify="center">
                          <v-btn
                            text
                            color="white accent-4"
                            @click="reveal = true"
                          >
                            Learn more
                          </v-btn>
                        </v-row>
                      </v-card-actions>

                      <v-expand-transition>
                        <v-card
                          v-if="reveal"
                          class="transition-fast-in-fast-out v-card--reveal"
                          style="height: 100%;"
                        >
                          <v-card-text class="pb-0">
                            <p class="display-1 "></p>
                            <p>
                              On a monthly basis, I compare fixed deposit
                              interest rates across SA. <br />I just updated it
                              for Feb. You can check out the best rates
                              <a
                                style="color: white"
                                @click.prevent="
                                  goto('https://ratecompare.co.za')
                                "
                                href="#"
                                >here</a
                              >
                            </p>
                          </v-card-text>
                          <v-card-actions class="pt-0">
                            <v-btn
                              text
                              color="teal accent-4"
                              @click="reveal = false"
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-expand-transition>
                    </v-card>
                  </td>
                </tr>
                <tr>
                  <th class="text-left"></th>
                  <th colspan="2" class="text-left">
                    <div v-if="timePeriod == 'Week' && rankings.length > 0">
                      <span
                        v-if="
                          selectedTimePeriod.endDate ==
                            selectedTimePeriod.actualEndDate
                        "
                      >
                        <v-icon color="golden">mdi-trophy</v-icon> Week's
                        winner:
                        <strong>
                          {{ rankings[0].name }}
                        </strong>
                      </span>
                      <span v-else>
                        <v-icon color="golden">mdi-trophy</v-icon>
                        Week's Winner: <strong>not yet declared</strong>
                      </span>
                    </div>
                  </th>

                  <th class="text-center">
                    Total Return (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  @click.prevent="rowClick(item)"
                  style="cursor: pointer;"
                  v-for="item in rankings"
                  :key="item.name"
                >
                  <td style="width: 25px;" class="text-center">
                    {{ item.rankingNr }}
                  </td>

                  <td style="max-width: 50px; width: 50px" class="text-left">
                    <v-avatar size="40">
                      <img :src="item.profileImage" />
                    </v-avatar>
                  </td>
                  <td class="text-left">
                    {{ item.name }}
                    <span v-if="item.weeklyWins > 0">
                      <v-icon size="x-small" style="color: #d1a684 "
                        >mdi-circle</v-icon
                      >
                      <small style="color: #d1a684 ">
                        {{ item.weeklyWins }}</small
                      >
                    </span>
                  </td>
                  <td class="text-center">
                    <strong> {{ item.trr }}</strong>
                  </td>
                </tr>

                <tr
                  v-if="!isAuthenticated"
                  @click.prevent="goToSignUp()"
                  style="cursor: pointer; "
                  class="grey lighten-4"
                >
                  <td style="width: 25px" class="text-center">
                    ?
                  </td>

                  <td style="max-width: 50px; width: 50px" class="text-left">
                    <v-avatar size="40">
                      <img
                        src="https://pbs.twimg.com/profile_images/811008944479019008/yXg3W-r-.jpg"
                      />
                    </v-avatar>
                  </td>
                  <td class="text-left">
                    Where would <strong>you</strong> end up...?
                  </td>
                  <td class="text-center">
                    <strong> ? </strong>
                  </td>
                </tr>
                <tr
                  v-else
                  @click.prevent="goToSignUp()"
                  style="cursor: pointer; "
                  class="grey lighten-4"
                >
                  <td style="width: 25px" class="text-center">
                    ?
                  </td>

                  <td style="max-width: 50px; width: 50px" class="text-left">
                    <v-avatar size="40">
                      <img :src="profileUrl" />
                    </v-avatar>
                  </td>
                  <td class="text-left">
                    {{ user.displayName }}
                  </td>
                  <td class="text-center">
                    <strong> ? </strong>
                  </td>
                </tr>
                <tr
                  @click.prevent="rowClick(item)"
                  style="cursor: pointer;"
                  v-for="item in rankings2"
                  :key="item.name"
                >
                  <td style="width: 25px;" class="text-center">
                    {{ item.rankingNr }}
                  </td>

                  <td style="max-width: 50px; width: 50px" class="text-left">
                    <v-avatar size="40">
                      <img :src="item.profileImage" />
                    </v-avatar>
                  </td>
                  <td class="text-left">
                    {{ item.name }}
                    <span v-if="item.weeklyWins > 0">
                      <v-icon size="x-small" style="color: #d1a684 "
                        >mdi-circle</v-icon
                      >
                      <small style="color: #d1a684 ">
                        {{ item.weeklyWins }}</small
                      >
                    </span>
                  </td>
                  <td class="text-center">
                    <strong> {{ item.trr }}</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4" style="text-align: left">
                    <v-card
                      class="grey lighten-3"
                      style="margin: 15px -5px 15px -5px"
                    >
                      <v-card-text
                        style="font-size: 0.9em; align: left; text-align: left"
                      >
                        <strong>Medals</strong><br />
                        <v-icon size="x-small" style="color: #d1a684 "
                          >mdi-circle</v-icon
                        >
                        # of weekly wins <br /><v-icon
                          size="x-small"
                          style="color: grey "
                          >mdi-circle</v-icon
                        >
                        # of monthly wins<br /><br />
                        <strong>Disclaimer</strong><br />
                        1. Stock picks come with a health warning. Keep away
                        from children.<br />
                        2. It's all for fun and a few bottles of champagne.<br />
                        3. No real money is won or lost here. Merely investment
                        bragging rights. <br />
                        4. Dividends are added to portfolio at ex-date. They are
                        not reinvested, but put into a Cash account earning 0%
                        interest. (*)
                        <br /><br />
                        <strong>PS. </strong> (*)0% interest!? That's terrible.
                        <strong>
                          I compare SA's best fixed deposits
                          <a
                            @click.prevent="goto('https://ratecompare.co.za')"
                            href="#"
                            >here</a
                          >.</strong
                        >
                      </v-card-text>
                    </v-card>
                    <v-card
                      v-if="subscribed == 'false' || subscribed == false"
                      style="margin: 15px -5px 15px -5px"
                      color="#1F7087"
                      dark
                    >
                      <v-card-text
                        v-if="!success && message == ''"
                        style="align: left; text-align: left"
                      >
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                        <v-btn @click.prevent="signup(email)" outlined rounded>
                          Sign up for updates
                        </v-btn>
                      </v-card-text>
                      <v-card-text
                        v-else-if="!success && message != ''"
                        style="align: left; text-align: left"
                      >
                        {{ message }}
                      </v-card-text>
                      <v-card-text
                        v-else-if="success"
                        style="align: left; text-align: left"
                      >
                        Thanks for signing up.
                      </v-card-text>
                    </v-card>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; font-size: 0.8em" colspan="4">
                    All rankings & prices last updated
                    {{ rankings[0].date }}.<v-spacer></v-spacer>

                    Created by Walter. You can follow me on Twitter
                    <v-icon>mdi-twitter</v-icon>
                    <a
                      href="#"
                      @click.prevent="goto('https://twitter.com/@ratecompare')"
                      >@ratecompare</a
                    >

                    <br />
                    <br />
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>

          <v-simple-table v-else style="width: 100%">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th colspan="2" class="text-left"></th>

                  <th class="text-center">
                    Total Return (%)
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in stockRankings" :key="item.name">
                  <td style="width: 25px;" class="text-center">
                    {{ index + 1 }}
                  </td>

                  <td style="max-width: 50px; width: 50px" class="text-left">
                    <v-avatar size="40" v-if="item.logo">
                      <img
                        :src="
                          'https://logo.clearbit.com/' + item.logo + '?size=80'
                        "
                      />
                    </v-avatar>
                    <v-avatar v-else size="40" color="black">
                      <span style="color: white">{{ item.name[0] }}</span>
                    </v-avatar>
                  </td>
                  <td class="text-left">
                    {{ item.name }}
                    <small>(held by #{{ item.nrHoldings }} portfolios)</small>
                  </td>
                  <td class="text-center">
                    <strong> {{ item.trr }}</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4" style="text-align: left">
                    <v-card
                      class="grey lighten-3"
                      style="margin: 15px -5px 15px -5px"
                    >
                      <v-card-text
                        style="font-size: 0.9em; align: left; text-align: left"
                      >
                        <strong>Disclaimer</strong><br />
                        1. Stock picks come with a health warning. Keep away
                        from children.<br />
                        2. It's all for fun and a few bottles of champagne.<br />
                        3. No real money is won or lost here. Merely investment
                        bragging rights.
                      </v-card-text>
                    </v-card>
                    <v-card
                      style="margin: 15px -5px 15px -5px"
                      color="#1F7087"
                      dark
                    >
                      <v-card-text
                        v-if="!success && message == ''"
                        style="align: left; text-align: left"
                      >
                        <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-mail"
                          required
                        ></v-text-field>
                        <v-btn @click.prevent="signup(email)" outlined rounded>
                          Sign up for updates
                        </v-btn>
                      </v-card-text>
                      <v-card-text
                        v-else-if="!success && message != ''"
                        style="align: left; text-align: left"
                      >
                        {{ message }}
                      </v-card-text>
                      <v-card-text
                        v-else-if="success"
                        style="align: left; text-align: left"
                      >
                        Thanks for signing up.
                      </v-card-text>
                    </v-card>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; font-size: 0.8em" colspan="4">
                    All rankings & prices last updated
                    {{ rankings[0].dateSk }}.<v-spacer></v-spacer
                    ><small>
                      Created by Walter from
                      <a
                        class="twitter-follow-button"
                        href="https://mymoneytree.co.za"
                      >
                        mymoneytree.co.za</a
                      ></small
                    >
                    <br />
                    <br />
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col
        v-if="false"
        cols="2"
        lg="2"
        xl="2"
        md="2"
        align="center"
        class="text-center top d-none d-lg-block"
      >
        <v-card dark class="ml-5 pt-5">
          <iframe
            style="width:120px;height:240px;"
            marginwidth="0"
            marginheight="0"
            scrolling="no"
            frameborder="0"
            src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=qf_sp_asin_til&ad_type=product_link&tracking_id=mymoneytree-20&marketplace=amazon&amp;region=US&placement=1897597495&asins=1897597495&linkId=4a6f87bff96e15ef73439ac269a45947&show_border=false&link_opens_in_new_window=false&price_color=333333&title_color=0066c0&bg_color=ffffff"
          >
          </iframe
          ><br />
          <v-card class="ma-1" style="font-size: 0.8em">
            <blockquote class="twitter-tweet">
              <p lang="en" dir="ltr">
                "Best book on markets. Never dates. <br />
                That with the Tao of Buffett."
              </p>
              &mdash;David Shapiro <br />
              <a
                href="https://twitter.com/davidshapiro61/status/1268839790427996166?ref_src=twsrc%5Etfw"
                >(@davidshapiro61)</a
              >
            </blockquote>
          </v-card>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import {
  getChallenges,
  getChallenge,
  getRankings,
  subscribe,
  getTimePeriods,
  getorderedstockstrr,
  logout,
  quicklog,
  BaseUrl,
  mySession
} from "@/gateway.js";
export default {
  name: "HelloWorld",
  async mounted() {
    await this.init();
    //
    await this.getResults();
    await this.getMySession();
  },
  data: () => ({
    reveal: false,
    profileUrl: "",
    challenges: [],
    drawer: false,
    challenge: null,
    timePeriod: "Overall",
    message: "",
    success: null,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    loading: false,
    rankings: [],
    rankings2: [],
    stockRankings: [],
    weeks: [],
    selectedWeek: null,

    timePeriods: [],
    selectedTimePeriod: null,
    tab: "overall"
  }),
  computed: {
    isAuthenticated() {
      return false;
      //if (this.$store.state.user.isAuthenticated)
      //   return this.$store.state.user.isAuthenticated;
      // else return false;
    },
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return null;
    },
    challengeId() {
      if (this.$store.state.challengeId) return this.$store.state.challengeId;
      else return 1;
    },
    subscribed() {
      if (this.$store.state.subscribed) return this.$store.state.subscribed;
      else return false;
    }
  },
  methods: {
    async getMySession() {
      const r = await mySession();
      if (r) {
        if (r.phoneNumber != "") {
          this.profileUrl = r.phoneNumber;
        }
      }
    },
    async submitLogin() {
      location.href = BaseUrl + "/auth/auth0";
    },
    async submitLoginTwitter() {
      location.href = BaseUrl + "/auth/twitter";
    },
    async submitLogout() {
      const redirectUrl = await logout();

      location.href = redirectUrl;
    },
    async goto(link) {
      await quicklog({ target: link });
      // let routeData = this.$router.resolve({name: 'routeName', query: {data: "someData"}});
      window.open(link, "_blank");
    },
    async init() {
      const cs = await getChallenges();
      if (cs) {
        this.challenges = cs.result;
      }

      const cr = await getChallenge({
        id: this.challengeId
      });
      if (cr) {
        this.challenge = cr.result;

        const tr = await getTimePeriods({
          dateType: "Overall",
          challengeId: this.challengeId
        });
        if (tr) {
          this.timePeriods = tr.results;
          this.selectedTimePeriod = tr.results[0];
        }
      }
    },
    changeChallenge(id) {
      this.$store.commit("challengeId", id);
      this.init();
      this.drawer = false;
      this.getResults();
    },
    async signup(email) {
      const response = await subscribe({ email: email });
      if (response) {
        this.success = response.results;
      }
    },
    rowClick(item) {
      // named route
      this.$router.push("/portfolio/" + item.portfolioId); //  this.$router.push("/portfolio/1"); // + item.portfolioId);
    },
    goToSignUp() {
      // named route
      this.$router.push("/signup/" + this.challengeId); //  this.$router.push("/portfolio/1"); // + item.portfolioId);
    },
    async changeDateType(val) {
      const tr = await getTimePeriods({
        dateType: val,
        challengeId: this.challengeId
      });
      if (tr) {
        this.timePeriods = tr.results;
        this.selectedTimePeriod = tr.results[0];
      }
      await this.getResults();
    },
    async getResults() {
      this.loading = true;
      if (this.tab == "holdings") {
        const response = await getorderedstockstrr({
          startDate: this.selectedTimePeriod.startDate,
          endDate: this.selectedTimePeriod.actualEndDate,
          challengeId: this.challengeId
        });
        if (response) {
          this.stockRankings = response.result;
          this.loading = false;
        }
      } else {
        console.log("challenge", this.challengeId);
        const response = await getRankings({
          startDate: this.selectedTimePeriod.startDate,
          endDate: this.selectedTimePeriod.actualEndDate,
          challengeId: this.challengeId
        });
        if (response) {
          var final = [];
          var final2 = [];

          for (var i = 0; i < 6; i++) {
            final.push(response.results[i]);
          }
          for (var j = 6; j < response.results.length; j++) {
            final2.push(response.results[j]);
          }
          this.rankings = final; //response.results;
          this.rankings2 = final2;
          this.loading = false;
        }
      }
    },
    async changeTab(val) {
      this.tab = val;

      await this.getResults();
    }
  }
};
</script>
<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
