/** @format */

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Challenges from "../components/Challenges.vue";
import PortfolioView from "../views/PortfolioView.vue";
//import Signup from "../components/Signup.vue";
import SignUp from "../components/Signup.vue";
import PickYourStock2 from "../components/PickYourStock2.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/portfolio/:id",
    name: "PortfolioView",
    component: PortfolioView
  },
  {
    path: "/challenges",
    name: "Challenges",
    component: Challenges
  },

  {
    path: "/signup/:id",
    name: "signup",
    component: SignUp
  },
  {
    path: "/pickyourstock/:id",
    name: "pickyourstock",
    component: PickYourStock2
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
