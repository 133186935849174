<!-- @format -->

<template>
  <v-container>
    <v-app-bar
      centered
      absolute
      color="#f7ef8a"
      dark
      src="https://images.squarespace-cdn.com/content/v1/54ad18f1e4b00f7c5fca6afa/1428405819686-JEVMR4OJNPU0B6D7S9J0/ke17ZwdGBToddI8pDm48kPqQfq0L3n3wpHIsRapTfg8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKczo5Zn4xktlpMsMj-QlHXeMfNK6GwvtVkYEWiR8XAPyD3GfLCe_DXOSC_YcAacWL_/image-asset.jpeg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>

      <v-toolbar-title
        ><router-link style="color: white; text-decoration: none" to="/"
          ><v-icon>mdi-arrow-left</v-icon> Back to Leaderboard</router-link
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension> </template>
    </v-app-bar>
    <v-row align="center" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-tabs>
          <v-tab @click.prevent="changeTab('overall')">Overall</v-tab>
          <v-tab @click.prevent="changeTab('week')">Week</v-tab>
        </v-tabs></v-col
      >
    </v-row>
    <br /><br />

    <v-row no-gutters align="center" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-card dark :loading="loading">
          <v-card dark>
            <v-row no-gutters align="left" justify="center" class="text-left">
              <v-card v-if="!user.portfolioId" color="blue-grey darken-1" dark>
                <template v-slot:progress>
                  <v-progress-linear
                    absolute
                    color="green lighten-3"
                    height="4"
                    indeterminate
                  ></v-progress-linear>
                </template>
                <v-img
                  height="200"
                  src="https://cdn.vuetifyjs.com/images/cards/dark-beach.jpg"
                >
                  <v-row>
                    <v-col class="text-right" cols="12"> </v-col>
                    <v-row class="pa-4" align="center" justify="center">
                      <v-col class="text-center">
                        <br />
                        <h3 class="headline">
                          {{ name }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-img>
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                          v-model="name"
                          :disabled="isUpdating"
                          filled
                          color="blue-grey lighten-2"
                          label="Name"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          @change="addStock"
                          v-model="selectedStock"
                          :items="searchStocks"
                          :loading="isUpdating"
                          :search-input.sync="search"
                          color="white"
                          item-text="name"
                          item-value="id"
                          label="Pick Stocks"
                          placeholder="Start typing to Search"
                          prepend-icon="mdi-bullseye-arrow"
                          return-object
                          hide-no-data
                          hide-selected
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-card
                        light
                        elevation="0"
                        style="width: 100%"
                        class="ma-2"
                        ><v-card-subtitle style="color: black"
                          >You have
                          <strong>{{ 5 - stocks.length }}</strong> picks
                          left</v-card-subtitle
                        >

                        <v-simple-table class="my-0">
                          <template v-slot:default>
                            <tbody>
                              <tr
                                v-for="(item, index) in stocks"
                                :key="item.id"
                              >
                                <td
                                  style="max-width: 50px; width: 50px"
                                  class="text-left"
                                >
                                  <v-avatar size="40" v-if="item.logo">
                                    <img
                                      v-if="item.zaTicker != 'CASHZAR'"
                                      :src="
                                        'https://logo.clearbit.com/' +
                                          item.logo +
                                          '?size=80'
                                      "
                                    />
                                    <img v-else :src="item.logo" />
                                  </v-avatar>
                                  <v-avatar v-else size="40" color="black">
                                    <span style="color: white">{{
                                      item.zaTicker
                                    }}</span>
                                  </v-avatar>
                                </td>
                                <td class="text-left">
                                  <span v-if="item.zaTicker != 'CASHZAR'">
                                    {{ item.name }}</span
                                  >
                                </td>
                                <td class="text-center">
                                  <a
                                    href="#"
                                    @click.prevent="removeStock(index)"
                                    >Remove</a
                                  >
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table></v-card
                      ></v-row
                    >
                  </v-container>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="stocks.length != 5"
                    :loading="isUpdating"
                    color="green-grey darken-3"
                    depressed
                    @click="submit"
                  >
                    <v-icon left>
                      mdi-update
                    </v-icon>
                    Submit Picks
                  </v-btn>
                </v-card-actions>
              </v-card>
              <v-card v-else color="blue-grey darken-1" dark>
                <template v-slot:progress>
                  <v-progress-linear
                    absolute
                    color="green lighten-3"
                    height="4"
                    indeterminate
                  ></v-progress-linear>
                </template>
                <v-img
                  height="200"
                  src="https://images.unsplash.com/photo-1595871213029-7476de0d71dd?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1522&q=80"
                >
                  <v-row>
                    <v-col class="text-right" cols="12"> </v-col>
                    <v-row class="pa-4" align="center" justify="center">
                      <v-col class="text-center">
                        <br />
                        <h3 class="headline">
                          {{ name }}
                        </h3>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-img>
                <v-form>
                  <v-container>
                    <v-row class="pa-5" align="center" justify="center">
                      <v-col cols="10">
                        <h1>It's official. Well done.</h1>
                        Our team of elves is carving out a marble stone slab
                        depicting your name and stock picks. They will be
                        lighting a candle and singing "Long may they live &
                        prosper". Once they have finished this ritual, they will
                        handwrite a personal letter and transcribe it into
                        electronic mail format to let you know that your
                        portfolio is up and running.

                        <br />
                        <br />
                        <br />Walter
                        <a
                          style="color:white"
                          href="https://twitter.com/@ratecompare"
                          >@ratecompare</a
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions v-if="user.portfolioId == 0">
                  <v-spacer></v-spacer>
                  <v-btn
                    :disabled="stocks.length != 5"
                    :loading="isUpdating"
                    color="green-grey darken-3"
                    depressed
                    @click="submit"
                  >
                    <v-icon left>
                      mdi-update
                    </v-icon>
                    Submit Picks
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-row>
          </v-card>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import { BaseUrl, getStocks, addStocks, logout } from "@/gateway.js";
export default {
  name: "HelloWorld",
  async mounted() {
    this.$store.dispatch("initialLoad");
    if (this.user) {
      this.name = this.$store.state.user.displayName;
      this.isUpdating = false;
    }
  },
  data: () => ({
    search: null,
    searchStocks: [],
    isUpdating: true,
    name: "",
    stocks: [],
    selectedStock: null,
    //
    loading: false,
    timePeriod: "Overall",
    holdings: [],
    portfolio: null,
    weeks: [],
    selectedWeek: null,
    tab: "overall",

    timePeriods: [],
    selectedTimePeriod: null,
    message: ""
  }),
  computed: {
    isAuthenticated() {
      if (this.$store.state.user.isAuthenticated)
        return this.$store.state.user.isAuthenticated;
      else return false;
    },
    user() {
      if (this.$store.state.user) return this.$store.state.user;
      else return null;
    },
    challengeId() {
      if (this.$store.state.challengeId) return this.$store.state.challengeId;
      else return 1;
    }
  },
  watch: {
    async search(val) {
      // Items have already been loaded
      //if (this.searchStocks.length > 0) return;

      // Items have already been requested
      //  if (this.isUpdating) return;

      this.isUpdating = true;

      // Lazily load input items
      const r = await getStocks({ searchText: val });
      if (r) {
        this.searchStocks = r.result;
        this.isUpdating = false;
      }
    }
  },
  methods: {
    async addStock() {
      //console.log("adding stock", this.selectedStock);
      this.stocks.push(this.selectedStock);
    },
    async submitLogin() {
      location.href = BaseUrl + "/auth/auth0?state=pickyourstock";
    },
    async submitLoginTwitter() {
      location.href = BaseUrl + "/auth/twitter";
    },
    async submitLogout() {
      const redirectUrl = await logout();

      location.href = redirectUrl;
    },
    async removeStock(index) {
      var final = [];
      for (var i = 0; i < this.stocks.length; i++) {
        if (index != i) {
          final.push(this.stocks[i]);
        }
      }
      this.stocks = final;
    },
    async submit() {
      const r = await addStocks({ tickers: this.stocks });
      if (r) {
        this.isUpdating = false;
      }
    }
  }
};
</script>
