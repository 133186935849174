/** @format */

import { JsonServiceClient } from "@servicestack/client";
import { Authenticate } from "@/authdtos";
export const BaseUrl = "https://stockmarketchallenge.azurewebsites.net/"; // "http://localhost:63333/"; //   // // // // //  ////
//
// : "portfoliolinkuat.azurewebsites.net; // .NET Core App Server on Azure

export const client = new JsonServiceClient(BaseUrl);

export const quicklog = async params => {
  const response = await client.get("/challenge/log/quick", params);
  return response;
};

export const mySession = async params => {
  const response = await client.get("/my-session", params);
  return response;
};

export const logout = async () => {
  localStorage.removeItem("ss-tok");
  const request = new Authenticate();
  request.provider = "logout";
  // await getSessionInfo();
  await client.post(request);

  return "/";
};

export const addStocks = async params => {
  const response = await client.postToUrl("/challenge/stocks/add", {
    tickers: params.tickers,
    challengeId: 1
  });
  return response;
};

export const getStocks = async params => {
  const response = await client.get("/challenge/stocks", params);
  return response;
};

export const getPortfolio = async params => {
  const response = await client.get("/challenge/portfolio", params);
  return response;
};

export const getRankings = async params => {
  const response = await client.get("/challenge/rankingtable", params);
  return response;
};

export const getorderedstockstrr = async params => {
  const response = await client.get("/challenge/orderedstockstrr", params);
  return response;
};

export const getWeeks = async params => {
  const response = await client.get("/challenge/weeks", params);
  return response;
};

export const getChallenge = async params => {
  const response = await client.get("/challenge/challenges/" + params.id);
  return response;
};

export const getChallenges = async params => {
  const response = await client.get("/challenge/challenges", params);
  return response;
};
export const getTimePeriods = async params => {
  const response = await client.get("/challenge/timeperiods", params);
  return response;
};

export const subscribe = async params => {
  const response = await client.get("/challenge/subscribe", params);

  return response;
};
