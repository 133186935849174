<!-- @format -->

<template>
  <v-container v-if="portfolio">
    <v-app-bar
      centered
      absolute
      color="#f7ef8a"
      dark
      src="https://images.squarespace-cdn.com/content/v1/54ad18f1e4b00f7c5fca6afa/1428405819686-JEVMR4OJNPU0B6D7S9J0/ke17ZwdGBToddI8pDm48kPqQfq0L3n3wpHIsRapTfg8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKczo5Zn4xktlpMsMj-QlHXeMfNK6GwvtVkYEWiR8XAPyD3GfLCe_DXOSC_YcAacWL_/image-asset.jpeg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>

      <v-toolbar-title
        ><router-link style="color: white; text-decoration: none" to="/"
          ><v-icon>mdi-arrow-left</v-icon> Back to Leaderboard</router-link
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs centered>
          <v-tab @click.prevent="changeTab('overall')">Holdings</v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab
          ><v-tab disabled @click.prevent="changeTab('week')"></v-tab
          ><v-tab disabled @click.prevent="changeTab('week')"></v-tab>
        </v-tabs>
      </template>
    </v-app-bar>
    <v-row align="center" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-tabs>
          <v-tab @click.prevent="changeTab('overall')">Overall</v-tab>
          <v-tab @click.prevent="changeTab('week')">Week</v-tab>
        </v-tabs></v-col
      >
    </v-row>
    <br /><br />

    <v-row
      v-if="portfolio"
      no-gutters
      align="center"
      justify="center"
      class="text-center"
    >
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-card :loading="loading">
          <v-card>
            <v-img height="200px" :src="portfolio.backgroundImage"> </v-img>
            <v-card-text style="height: 50px; position: relative; ">
              <v-fab-transition>
                <v-btn class="ml-8" dark absolute top left fab>
                  <v-avatar size="100" dark>
                    <img alt="user" :src="portfolio.profileImage" />
                  </v-avatar>
                </v-btn>
              </v-fab-transition>
            </v-card-text>
            <v-card-title class="pt-0 pb-0 pl-8 text-left">
              {{ portfolio.name }}
              <span v-if="portfolio.weeklyWins > 0" style="font-size: 0.6em ">
                <v-icon size="small" style="color: #d1a684 ">mdi-circle</v-icon>
                <small style="color: #d1a684;  ">
                  {{ portfolio.weeklyWins }}</small
                >
              </span>
            </v-card-title>

            <v-card-text class="text--primary text-left pl-8">
              <v-icon v-if="portfolio.twitterHandle" color="blue"
                >mdi-twitter</v-icon
              >
              <a
                class="twitter-follow-button "
                target="_blank"
                :href="'https://twitter.com/' + portfolio.twitterHandle"
              >
                {{ portfolio.twitterHandle }}</a
              >
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title
              style="padding: 5px 18px 5px 18px; font-size: 0.9em"
              class="grey lighten-3"
            >
              <v-select
                dense
                style="font-size: 0.8em; padding: 3px 0px 0px 0px;"
                @change="getResults"
                return-object
                :items="timePeriods"
                item-text="name"
                item-value="id"
                single-line="true"
                label="Select"
                v-model="selectedTimePeriod"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle tile group v-model="timePeriod">
                <v-btn
                  @click.prevent="changeDateType('Overall')"
                  small
                  value="Overall"
                >
                  Overall
                </v-btn>

                <v-btn small @click.prevent="changeDateType('Day')" value="Day">
                  Day
                </v-btn>

                <v-btn
                  small
                  @click.prevent="changeDateType('Week')"
                  value="Week"
                >
                  Week
                </v-btn>

                <v-btn
                  small
                  @click.prevent="changeDateType('Month')"
                  value="Month"
                >
                  Month
                </v-btn>
              </v-btn-toggle>
            </v-card-title>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="2" class="text-left"></th>

                    <th class="text-center">
                      Total Return (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in portfolio.portfolioHoldings"
                    :key="item.holdingName"
                  >
                    <td style="max-width: 50px; width: 50px" class="text-left">
                      <v-avatar size="40" v-if="item.logo">
                        <img
                          v-if="item.code != 'CASHZAR'"
                          :src="
                            'https://logo.clearbit.com/' +
                              item.logo +
                              '?size=80'
                          "
                        />
                        <img v-else :src="item.logo" />
                      </v-avatar>
                      <v-avatar v-else size="40" color="black">
                        <span style="color: white">{{
                          item.holdingName[0]
                        }}</span>
                      </v-avatar>
                    </td>
                    <td class="text-left">
                      <span v-if="item.code != 'CASHZAR'">
                        {{ item.holdingName }}</span
                      >
                      <span v-else
                        >Cash - Dividends received
                        <small>earning 0% p.a (*)</small><br />
                      </span>
                    </td>
                    <td class="text-center">
                      <strong>
                        <span v-if="item.code != 'CASHZAR'">
                          {{ item.trr }}</span
                        >
                        <span v-else>R {{ item.units }}</span>
                      </strong>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr v-if="dividendsReceived > 0">
                    <td style="text-align: left; font-size: 0.7em" colspan="4">
                      (*) Total dividends received from stock picks assuming an
                      initial investment of R100k.
                    </td>
                  </tr>
                  <tr>
                    <td style="text-align: left; font-size: 0.6em" colspan="4">
                      <v-divider></v-divider><br />
                      last updated {{ portfolio.portfolioHoldings[0].date }}
                    </td>
                  </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-card>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import { getPortfolio, getTimePeriods } from "@/gateway.js";
export default {
  name: "HelloWorld",
  async mounted() {
    const tr = await getTimePeriods({ dateType: "Overall" });
    if (tr) {
      this.timePeriods = tr.results;
      this.selectedTimePeriod = tr.results[0];
      await this.getResults();
    }
  },
  data: () => ({
    loading: false,
    timePeriod: "Overall",
    holdings: [],
    portfolio: null,
    weeks: [],
    selectedWeek: null,
    tab: "overall",

    timePeriods: [],
    selectedTimePeriod: null
  }),
  computed: {
    dividendsReceived() {
      var f = 0;
      for (var i = 0; i < this.portfolio.portfolioHoldings.length; i++) {
        var h = this.portfolio.portfolioHoldings[i];
        if (h.code == "CASHZAR") {
          f += h.units;
        }
      }
      return f;
    }
  },
  methods: {
    async changeDateType(val) {
      const tr = await getTimePeriods({ dateType: val });
      if (tr) {
        this.timePeriods = tr.results;
        this.selectedTimePeriod = tr.results[0];
      }
      await this.getResults();
    },
    rowClick(item) {
      console.log(item);
    },
    async getResults() {
      this.loading = true;
      const r = await getPortfolio({
        portfolioId: this.$route.params.id,
        startDate: this.selectedTimePeriod.startDate,
        endDate: this.selectedTimePeriod.actualEndDate
      });

      if (r) {
        this.portfolio = r.result;
        this.loading = false;
      }
    },
    async changeTab(val) {
      this.tab = val;

      await this.getResults();
    }
  }
};
</script>
