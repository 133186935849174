/** @format */

import Vue from "vue";
import Vuex from "vuex";
import { mySession } from "@/gateway.js";
Vue.use(Vuex);

const actions = {
  async initialLoad(context) {
    const session = await mySession();
    console.log("session", session);
    context.commit("sessionInfo", session);

    if (localStorage.getItem("smc-challenge-id")) {
      context.commit("challengeId", localStorage.getItem("smc-challenge-id"));
    } else {
      localStorage.setItem("smc-challenge-id", 1);
      context.commit("challengeId", 1);
    }

    if (localStorage.getItem("smc-subscribed")) {
      console.log(
        "localStorage.getItem(smc-subscribed)",
        localStorage.getItem("smc-subscribed")
      );
      context.commit("subscribed", localStorage.getItem("smc-subscribed"));
    } else {
      localStorage.setItem("smc-subscribed", false);
      context.commit("subscribed", false);
    }
  },
  async setLoading(context) {
    context.commit("loading", context);
  }
};

const mutations = {
  challengeId(state, config) {
    state.challengeId = config;
  },
  subscribed(state, config) {
    localStorage.setItem("smc-subscribed", config);
    state.subscribed = config;
  },
  loading(state, config) {
    state.loading = config;
  },
  sessionInfo(state, user) {
    console.log("sessionInfo", state, user);
    state.user.id = user.userAuthId; // user.id;
    state.user.isAuthenticated = user.isAuthenticated;
    state.user.portfolioId = user.portfolioId;
    state.user.username = user.userName;
    state.user.email = user.email;
    state.user.displayName = user.displayName;
    state.user.profileUrl = user.phoneNumber;
    state.user.roles = user.roles;
    state.user.permissions = user.permissions;
    console.log("state.user", state.user);
  }
};

export const getters = {
  isAuthenticated: state => state.user.isAuthenticated == true
};

export default new Vuex.Store({
  state: {
    user: {},
    challengeId: 1,
    subscribed: false,

    loading: false
  },
  mutations: mutations,
  actions: actions,
  getters: getters,
  modules: {}
});
