<!-- @format -->

<template>
  <v-container>
    <v-app-bar
      centered
      absolute
      color="#f7ef8a"
      dark
      src="https://images.squarespace-cdn.com/content/v1/54ad18f1e4b00f7c5fca6afa/1428405819686-JEVMR4OJNPU0B6D7S9J0/ke17ZwdGBToddI8pDm48kPqQfq0L3n3wpHIsRapTfg8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKczo5Zn4xktlpMsMj-QlHXeMfNK6GwvtVkYEWiR8XAPyD3GfLCe_DXOSC_YcAacWL_/image-asset.jpeg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>

      <v-toolbar-title
        ><router-link style="color: white; text-decoration: none" to="/"
          ><v-icon>mdi-arrow-left</v-icon> Back to Leaderboard</router-link
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <template v-slot:extension> </template>
    </v-app-bar>
    <v-row align="center" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-tabs>
          <v-tab @click.prevent="changeTab('overall')">Overall</v-tab>
          <v-tab @click.prevent="changeTab('week')">Week</v-tab>
        </v-tabs></v-col
      >
    </v-row>
    <br /><br />

    <v-row no-gutters align="center" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-card dark :loading="loading">
          <v-card dark>
            <v-img
              height="200px"
              src="https://www.wallpaperup.com/uploads/wallpapers/2014/03/24/307595/f38ae29be31e4c280ad5e2e9ed9fc716-1000.jpg"
            >
            </v-img>
            <v-card-text style="height: 50px; position: relative; ">
              <v-fab-transition>
                <v-btn class="ml-8" dark absolute top left fab>
                  <v-avatar size="100" dark>
                    <img
                      alt="user"
                      src="https://pbs.twimg.com/profile_images/811008944479019008/yXg3W-r-.jpg"
                    />
                  </v-avatar>
                </v-btn>
              </v-fab-transition>
            </v-card-text>
            <v-card-title class="pt-0 pb-0 pl-15 text-left"> </v-card-title>
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="text-center"
            >
              <v-col center cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
                <v-card
                  v-if="subscribed == 'true'"
                  dark
                  class="mx-auto my-0"
                  elevate
                >
                  <v-card-title>Psst. I am working on this. </v-card-title>

                  <v-card-text>
                    <v-row align="left" class="pa-0 mx-0">
                      <div class="white--text ml-0">
                        Hold onto your horses. You will get an email soon.
                      </div>
                    </v-row>
                    <br />

                    <v-row align="left" class="mx-0">
                      <div class="white--text ml-0">
                        Walter
                      </div>
                    </v-row>
                    <br />
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn>
                        <router-link
                          style="color: white; text-decoration: none"
                          to="/"
                          ><v-icon>mdi-arrow-left</v-icon> Back to
                          Leaderboard</router-link
                        >
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
                <v-card
                  v-else-if="message == ''"
                  dark
                  class="mx-auto my-0"
                  elevate
                >
                  <v-card-title>Pssst...</v-card-title>

                  <v-card-text>
                    <v-row align="center" class="pa-0 mx-0">
                      <div class="white--text ml-0">
                        How would you rank against the pros?
                      </div>
                    </v-row>
                  </v-card-text>

                  <v-divider class="mx-4"></v-divider>

                  <v-card-text>
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="E-mail"
                      required
                    ></v-text-field>
                    <v-btn @click.prevent="signup(email)" outlined rounded>
                      I'm interested *
                    </v-btn>
                    <br />
                    <small
                      >*limited early spots available
                      <br />
                      your portfolio & ranking will only be visible to you.
                    </small>
                  </v-card-text>
                </v-card>
                <v-card v-else dark class="mx-auto my-0" elevate>
                  <v-card-title
                    >Nice, let's see how good you are...</v-card-title
                  >

                  <v-card-text>
                    <v-row align="left" class="pa-0 mx-0">
                      <div class="white--text ml-0">
                        Look out for an email from me with more details.
                      </div>
                    </v-row>
                    <br />

                    <v-row align="left" class="mx-0">
                      <div class="white--text ml-0">
                        Walter
                      </div>
                    </v-row>
                    <br />
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-btn>
                        <router-link
                          style="color: white; text-decoration: none"
                          to="/"
                          ><v-icon>mdi-arrow-left</v-icon> Back to
                          Leaderboard</router-link
                        >
                      </v-btn>
                    </v-card-actions>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
import { getTimePeriods, subscribe } from "@/gateway.js";

export default {
  name: "HelloWorld",
  async mounted() {
    const tr = await getTimePeriods({ dateType: "Overall" });
    if (tr) {
      this.timePeriods = tr.results;
      this.selectedTimePeriod = tr.results[0];
      await this.getResults();
    }
  },
  data: () => ({
    loading: false,
    timePeriod: "Overall",
    holdings: [],
    portfolio: null,
    weeks: [],
    selectedWeek: null,
    tab: "overall",

    timePeriods: [],
    selectedTimePeriod: null,
    message: ""
  }),
  computed: {
    subscribed() {
      if (this.$store.state.subscribed) return this.$store.state.subscribed;
      else return false;
    }
  },
  methods: {
    async signup(email) {
      const response = await subscribe({ email: email, tag: "Pssst" });
      if (response) {
        this.success = response.results;
        localStorage.setItem("smc-subscribed", true);
        this.$store.commit("subscribed", true);
        this.message = "Subscribed";
      }
    },
    async changeDateType(val) {
      const tr = await getTimePeriods({ dateType: val });
      if (tr) {
        this.timePeriods = tr.results;
        this.selectedTimePeriod = tr.results[0];
      }
      await this.getResults();
    },
    rowClick(item) {
      console.log(item);
    },
    async getResults() {
      this.loading = false;
    },
    async changeTab(val) {
      this.tab = val;

      await this.getResults();
    }
  }
};
</script>
