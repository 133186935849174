<!-- @format -->

<template>
  <v-container>
    <v-app-bar
      centered
      absolute
      color="#f7ef8a"
      dark
      src="https://images.squarespace-cdn.com/content/v1/54ad18f1e4b00f7c5fca6afa/1428405819686-JEVMR4OJNPU0B6D7S9J0/ke17ZwdGBToddI8pDm48kPqQfq0L3n3wpHIsRapTfg8UqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKczo5Zn4xktlpMsMj-QlHXeMfNK6GwvtVkYEWiR8XAPyD3GfLCe_DXOSC_YcAacWL_/image-asset.jpeg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
        ></v-img>
      </template>
      <v-spacer></v-spacer>
      <v-toolbar-title>Challenges </v-toolbar-title>

      <v-spacer></v-spacer>

      <template v-slot:extension>
        <v-tabs centered>
          <v-tab @click.prevent="changeTab('overall')">Leaderboard</v-tab>
          <v-tab @click.prevent="changeTab('holdings')">Holdings</v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab>
          <v-tab disabled @click.prevent="changeTab('week')"></v-tab
          ><v-tab disabled @click.prevent="changeTab('week')"></v-tab
          ><v-tab disabled @click.prevent="changeTab('week')"></v-tab>
        </v-tabs>
      </template>
    </v-app-bar>

    <br /><br />
    <br /><br />
    <br />

    <v-row no-gutters align="center" justify="center" class="text-center">
      <v-col cols="12" xs="12" lg="8" xl="6" sm="12" md="8">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-card>
          <v-card-title style="padding: 5px 5px 0 18px" class="grey lighten-3">
          </v-card-title>
          <v-divider class="mx-4"></v-divider>
          <v-simple-table
            v-if="tab == 'overall'"
            style="width: 100%"
            single-select="true"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left"></th>
                  <th colspan="2" class="text-left"></th>

                  <th class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  @click.prevent="rowClick(item)"
                  style="cursor: pointer;"
                  v-for="item in challenges"
                  :key="item.name"
                >
                  <td style="width: 25px;" class="text-center">
                    {{ item }}
                  </td>

                  <td style="max-width: 50px; width: 50px" class="text-left">
                    <v-avatar size="40"> </v-avatar>
                  </td>
                  <td class="text-left">
                    {{ item.name }}
                  </td>
                  <td class="text-center">
                    <strong> {{ item.description }}</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getChallenges, subscribe } from "@/gateway.js";
export default {
  name: "HelloWorld",
  async mounted() {
    this.loading = true;

    //
    await this.getResults();
  },
  data: () => ({
    timePeriod: "Overall",
    message: "",
    success: null,
    email: "",
    emailRules: [
      v => !!v || "E-mail is required",
      v => /.+@.+/.test(v) || "E-mail must be valid"
    ],
    loading: false,
    challenges: [],

    tab: "overall"
  }),
  methods: {
    async signup(email) {
      const response = await subscribe({ email: email });
      if (response) {
        this.success = response.results;
      }
    },
    rowClick(item) {
      // named route
      this.$router.push("/portfolio/" + item.portfolioId); //  this.$router.push("/portfolio/1"); // + item.portfolioId);
    },
    async changeDateType() {
      await this.getResults();
    },
    async getResults() {
      this.loading = true;

      const response = await getChallenges({});
      if (response) {
        this.challenges = response.result;
        this.loading = false;
      }
    },
    async changeTab(val) {
      this.tab = val;

      await this.getResults();
    }
  }
};
</script>
